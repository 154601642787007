import { Box, Container, Typography, Grid } from "@mui/material";

// Terms and Conditions Page Component
const TermsAndConditionsPage = () => {
  return (
    <Box sx={{ py: { xs: "60px", sm: "80px" }, bgcolor: "#f5f5f0", width: "100%" }}>
      <Container maxWidth="lg">
        {/* Section Title */}
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "'Anonymous_Pro-Bold', Helvetica",
              fontWeight: "bold",
              color: "#1f1f20",
              fontSize: { xs: "2rem", sm: "2.8rem", md: "3.8rem" },
              lineHeight: "1.2",
              mb: 2,
            }}
          >
            Terms and Conditions
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Figtree-Medium', Helvetica",
              fontWeight: 500,
              color: "#5d5c58",
              fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" },
              lineHeight: "1.7",
              maxWidth: "800px",
              mx: "auto",
            }}
          >
            Please read these Terms and Conditions carefully before using our services. By using our services, you agree to be bound by these terms.
          </Typography>
        </Box>

        {/* Terms and Conditions Sections */}
        <Grid container spacing={4} sx={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={10}>
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                1. Introduction
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                These Terms and Conditions govern the use of the services provided by IT SUPPORT 1 s.r.o. By accessing or using our services, you agree to comply with and be bound by these terms.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                2. Service Use
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                You agree to use our services only for lawful purposes and in accordance with these terms. You will not engage in any conduct that may harm the reputation or functioning of our services.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                3. User Obligations
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                As a user, you agree to provide accurate information when using our services and to follow any applicable regulations or laws related to the use of our services.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                4. Payment Terms
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                Our services are provided for a fee, which will be communicated to you before purchasing. Payments must be made according to the specified methods, such as via Stripe, PayPal, or another third-party processor. All fees are non-refundable except as specified in our Refund Policy.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                5. Limitation of Liability
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                IT SUPPORT 1 s.r.o. shall not be liable for any direct, indirect, incidental, or consequential damages arising out of the use or inability to use our services, even if we have been advised of the possibility of such damages.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                6. Termination
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                We may terminate or suspend your access to our services if you violate these Terms and Conditions or if required by law. Upon termination, your right to use our services will cease immediately.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                7. Changes to Terms
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                IT SUPPORT 1 s.r.o. reserves the right to modify or update these Terms and Conditions at any time. Any changes will be posted on this page, and they will be effective immediately upon publication.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                8. Contact Information
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                If you have any questions about these Terms and Conditions, please contact us at:
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                Email: info@itandtechconsulting.com <br />
                Phone: +421 950 684 070 <br />
                Address: Hlavná 643/26 Kráľovský Chlmec 077 01 Slovakia 
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TermsAndConditionsPage;