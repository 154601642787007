import { Box, Container, Typography, Grid } from "@mui/material";

// Legal Notice Page Component
const LegalNoticePage = () => {
  return (
    <Box sx={{ py: { xs: "60px", sm: "80px" }, bgcolor: "#f5f5f0", width: "100%" }}>
      <Container maxWidth="lg">
        {/* Section Title */}
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "'Anonymous_Pro-Bold', Helvetica",
              fontWeight: "bold",
              color: "#1f1f20",
              fontSize: { xs: "2rem", sm: "2.8rem", md: "3.8rem" },
              lineHeight: "1.2",
              mb: 2,
            }}
          >
            Legal Notice
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Figtree-Medium', Helvetica",
              fontWeight: 500,
              color: "#5d5c58",
              fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" },
              lineHeight: "1.7",
              maxWidth: "800px",
              mx: "auto",
            }}
          >
            This page outlines the legal details regarding the services provided by IT SUPPORT 1 s.r.o. 
            Please review these terms before using our services.
          </Typography>
        </Box>

        {/* Legal Notice Sections */}
        <Grid container spacing={4} sx={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={10}>
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                1. Company Information
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                IT SUPPORT 1 s.r.o.  is a company registered in SK
                The registered office is located at: Hlavná 643/26 Kráľovský Chlmec 077 01 Slovakia 
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                2. Intellectual Property Rights
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                All content and materials available on this website, including text, images, graphics, and logos, are protected by intellectual property rights and are the property of IT SUPPORT 1 s.r.o. or licensed to it.
                You may not reproduce, distribute, or otherwise use the materials without prior consent from IT SUPPORT 1 s.r.o.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                3. Disclaimer of Liability
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                IT SUPPORT 1 s.r.o. does not guarantee the accuracy, completeness, or reliability of any content or information available on this website. We are not responsible for any direct, indirect, or incidental damages resulting from the use or inability to use our services or content.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                4. Governing Law and Jurisdiction
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                This Legal Notice is governed by the laws of [Country/Region]. Any dispute arising out of or related to these terms shall be subject to the exclusive jurisdiction of the courts in [Jurisdiction].
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                5. Changes to Legal Notice
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                IT SUPPORT 1 s.r.o. reserves the right to update or change this Legal Notice at any time. All changes will be reflected on this page and will be effective as soon as they are posted. We encourage you to review this page regularly for updates.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                6. Contact Information
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                If you have any questions about this Legal Notice, or if you need further information, please contact us at:
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                Email: info@itandtechconsulting.com <br />
                Phone: +421 950 684 070 <br />
                Address: Hlavná 643/26 Kráľovský Chlmec 077 01 Slovakia 
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LegalNoticePage;