import { Box, Container, Typography, Grid } from "@mui/material";

// Refund Policy Page Component
const RefundPolicyPage = () => {
  return (
    <Box sx={{ py: { xs: "60px", sm: "80px" }, bgcolor: "#f5f5f0", width: "100%" }}>
      <Container maxWidth="lg">
        {/* Section Title */}
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "'Anonymous_Pro-Bold', Helvetica",
              fontWeight: "bold",
              color: "#1f1f20",
              fontSize: { xs: "2rem", sm: "2.8rem", md: "3.8rem" },
              lineHeight: "1.2",
              mb: 2,
            }}
          >
            Refund Policy
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Figtree-Medium', Helvetica",
              fontWeight: 500,
              color: "#5d5c58",
              fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" },
              lineHeight: "1.7",
              maxWidth: "800px",
              mx: "auto",
            }}
          >
            Our Refund Policy ensures that our clients are satisfied with the remote IT support services we provide.
            Please read the following terms carefully before making any purchase.
          </Typography>
        </Box>

        {/* Policy Sections */}
        <Grid container spacing={4} sx={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={10}>
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                1. Refund Eligibility
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                We offer refunds for services that were not provided as promised or if there was an issue with
                the quality of the remote IT support provided. If you feel that our services were not up to your
                expectations, you are eligible for a refund within 7 days of purchase, provided the issue is not
                caused by the user's own actions.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                2. How to Request a Refund
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                To request a refund, please contact our support team by email at info@itandtechconsulting.com or call
                us at +421 950 684 070. Please provide details of the issue you're facing, including your
                purchase information, and a description of why you are requesting the refund.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                3. Refund Processing
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                Once your refund request has been submitted, our team will review the information provided and
                determine if the refund is applicable. Refunds will be processed within 5-7 business days and
                credited back to the original payment method used during the purchase. Please note that the
                processing time may vary based on the payment provider.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                4. Non-Refundable Services
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                Some services may not be eligible for a refund. These include but are not limited to:
                - Services that have been provided in full and to the satisfaction of the customer.
                - Services for which the customer has changed the scope or requirements after delivery.
                - Purchases made more than 7 days prior to the refund request.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                5. Changes to This Refund Policy
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                We reserve the right to update or change this Refund Policy at any time. Any updates will be
                posted on this page with a revised "Last Updated" date. We encourage you to review this page
                periodically for any changes.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                6. Contact Us
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                If you have any questions or concerns about this Refund Policy or need to request a refund,
                please contact us at:
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                Email: info@itandtechconsulting.com <br />
                Phone: +421 950 684 070 <br />
                Address: Hlavná 643/26 Kráľovský Chlmec 077 01 Slovakia 
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default RefundPolicyPage;