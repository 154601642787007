import { Box, Container, Grid, Typography, Button, Stack } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment"; // Stripe Icon
import React, { useState, useEffect } from 'react';

// Data for packages
const packages = [
  {
    title: "Basic",
    description:
      "Ideal for businesses with minimal IT support needs. This package provides 1 remote IT support session, perfect for addressing urgent or occasional technical issues.",
    price: "€20.00",
    sessions: "1 Session of Remote IT Support",
    stripeLink: "https://buy.stripe.com/4gw7sLdWPgTz1b2fYY"
  },
  {
    title: "Premium",
    description:
      "For businesses that require ongoing IT assistance. This package includes up to 5 remote IT support sessions per month, offering priority support and faster response times.",
    price: "€60.00",
    sessions: "Up to 5 Remote IT Support Sessions per Month",
    stripeLink: "https://buy.stripe.com/00g4gzg4Xavb06Y001"
  },
  {
    title: "Platinum",
    description:
      "Our most comprehensive package, offering unlimited remote IT support sessions. With this plan, you get access to 24/7 support, ensuring that your IT systems are always optimized and up and running.",
    price: "€200.00/year",
    sessions: "Unlimited Remote IT Support Sessions",
    stripeLink: "https://buy.stripe.com/fZe00j6un0UB8Du3ce"
  },
];

const RemoteSupportPackages = () => {
  return (
    <Box sx={{ py: { xs: "60px", sm: "80px" }, bgcolor: "#dadfd0", width: "100%" }}>
      <Container maxWidth="lg">
        {/* Section Title and Description */}
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "'Anonymous_Pro-Bold', Helvetica",
              fontWeight: "bold",
              color: "#1f1f20",
              fontSize: { xs: "2rem", sm: "2.8rem", md: "3.8rem" },
              lineHeight: "1.2",
              mb: 2,
            }}
          >
            Reliable Remote Solutions
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Figtree-Medium', Helvetica",
              fontWeight: 500,
              color: "#5d5c58",
              fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" },
              lineHeight: "1.7",
              maxWidth: "800px",
              mx: "auto",
            }}
          >
            Streamline Your IT Support with Our Efficient Remote Helpdesk Service. Our Skilled Technicians
            Deliver Prompt and Effective Solutions to Keep Your Systems Running at Peak Performance.
            Choose the Package That Fits Your Business Needs:
          </Typography>
        </Box>

        {/* Package Grid */}
        <Grid container spacing={4} sx={{ justifyContent: "center", flexWrap: {xs: "wrap", sm: "nowrap"}}}>
          {packages.map((item, index) => (
            <Grid item xs={12} sm={4} key={index} sx={{marginTop: {xs: "65px", sm: "unset"}}}>
              <Box
                sx={{
                  bgcolor: "#f5f5f0",
                  borderRadius: "20px",
                  p: 4,
                  textAlign: "center",
                  height: "100%",
                  boxShadow: "0px 4px 20px rgba(0,0,0,0.05)",
                  transition: "transform 0.3s",
                  alignItems: "space-between",
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "column",
                  "&:hover": {
                    transform: "translateY(-5px)",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "'Figtree-SemiBold', Helvetica",
                    fontWeight: 600,
                    color: "#1f1f20",
                    mb: 2,
                    fontSize: "1.8rem",
                  }}
                >
                  {item.title}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "'Figtree-Regular', Helvetica",
                    color: "#62615e",
                    mb: 3,
                    fontSize: "1rem",
                    flex: 1
                  }}
                >
                  {item.description}
                </Typography>

                {/* Stripe Payment Button with Icon */}
                <a href={item.stripeLink} target="_blank" rel="noopener noreferrer" style={{textDecoration:"none", display: "flex", justifyContent: 'center'}}>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#f88a3e",
                      color: "#712e12",
                      fontFamily: "'Figtree-SemiBold', Helvetica",
                      textTransform: "none",
                      borderRadius: "40px",
                      py: 1.5,
                      px: 4,
                      fontSize: "1rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PaymentIcon sx={{ mr: 1 }} /> {`Buy for ${item.price}`}
                  </Button>
                </a>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default RemoteSupportPackages;