import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Paper,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  AppBar,
  Toolbar,
} from "@mui/material";
import { 
  AccessTime, 
  CalendarMonth, 
  ArrowBackIos, 
  ArrowForwardIos,
  CheckCircle,
  PersonOutline,
  Email,
  Phone,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const BookingPage = () => {
  // State for calendar
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [bookingFormOpen, setBookingFormOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    notes: "",
  });

  // Available services
  const service = {
    name: "Remote Technical Support",
    duration: "1 hour"
  };

  // Generate calendar days
  const generateCalendarDays = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    
    const daysInMonth = lastDay.getDate();
    const startDayOfWeek = firstDay.getDay(); // 0 is Sunday
    
    const days = [];
    
    // Add empty cells for days before the first day of the month
    for (let i = 0; i < startDayOfWeek; i++) {
      days.push({ day: null, available: false });
    }
    
    // Add actual days of the month
    for (let i = 1; i <= daysInMonth; i++) {
      // All days are available except weekends
      const dayDate = new Date(year, month, i);
      const dayOfWeek = dayDate.getDay();
      const isAvailable = dayOfWeek !== 0 && dayOfWeek !== 6; // Not Sunday(0) or Saturday(6)
      days.push({ day: i, available: isAvailable });
    }
    
    return days;
  };

  const calendarDays = generateCalendarDays();
  
  // Time slots - fixed availability during business hours
  const getTimeSlots = () => {
    const slots = [];
    const startHour = 9; // 9 AM
    const endHour = 17; // 5 PM
    
    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minutes of [0, 30]) { // Half-hour increments
        if (hour === endHour && minutes > 0) continue; // Don't go past end time
        
        const time = `${hour % 12 || 12}:${minutes === 0 ? '00' : minutes} ${hour >= 12 ? 'PM' : 'AM'}`;
        slots.push({ time, available: true });
      }
    }
    
    return slots;
  };

  const timeSlots = getTimeSlots();

  // Handler for month navigation
  const handleMonthChange = (direction) => {
    const newDate = new Date(currentDate);
    if (direction === "prev") {
      newDate.setMonth(newDate.getMonth() - 1);
    } else {
      newDate.setMonth(newDate.getMonth() + 1);
    }
    setCurrentDate(newDate);
  };

  // Handler for date selection
  const handleDateSelect = (day) => {
    if (day && day.available) {
      const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day.day);
      setSelectedDate(newDate);
      setSelectedTimeSlot(null); // Reset time slot when date changes
    }
  };

  // Handler for time slot selection
  const handleTimeSlotSelect = (slot) => {
    if (slot.available) {
      setSelectedTimeSlot(slot);
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setBookingFormOpen(false);
    setConfirmationOpen(true);
  };

  // Render weekday headers
  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Format month and year display
  const monthYearFormat = new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(currentDate);

  // Format selected date for display
  const formatSelectedDate = (date) => {
    if (!date) return '';
    return new Intl.DateTimeFormat('en-US', { weekday: 'long', month: 'long', day: 'numeric' }).format(date);
  };

  return (
    <Box sx={{ bgcolor: "#f5f5f5", width: "100%" }}>
      {/* Navigation Bar */}
      <AppBar position="fixed" color="default" elevation={1}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Remote Support Booking
          </Typography>
        </Toolbar>
      </AppBar>

      <Container maxWidth="lg" sx={{ pt: 10, pb: 8 }}>
        {/* Brief Explanation */}
        <Paper elevation={0} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
            Book Your Remote Support Session
          </Typography>
          <Typography variant="body1" paragraph>
            Our technical experts are ready to help you solve your IT issues remotely. 
            Select a date and time below to schedule your session.
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            <AccessTime fontSize="small" sx={{ verticalAlign: "middle", mr: 0.5 }} />
            Session Duration: {service.duration}
          </Typography>
        </Paper>

        <Grid container spacing={3}>
          {/* Calendar */}
          <Grid item xs={12} md={7}>
            <Paper elevation={0} sx={{ p: 3, borderRadius: 2 }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                Select a Date
              </Typography>

              {/* Calendar Navigation */}
              <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Button
                  startIcon={<ArrowBackIos />}
                  onClick={() => handleMonthChange("prev")}
                >
                  Previous
                </Button>
                <Typography variant="h6" sx={{ fontWeight: "medium" }}>
                  {monthYearFormat}
                </Typography>
                <Button
                  endIcon={<ArrowForwardIos />}
                  onClick={() => handleMonthChange("next")}
                >
                  Next
                </Button>
              </Stack>

              {/* Calendar Grid */}
              <Box>
                {/* Weekday headers */}
                <Grid container>
                  {weekDays.map((day) => (
                    <Grid item xs={12 / 7} key={day}>
                      <Typography
                        align="center"
                        sx={{ fontWeight: "medium", py: 1 }}
                      >
                        {day}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>

                {/* Calendar days */}
                <Grid container>
                  {calendarDays.map((day, index) => (
                    <Grid item xs={12 / 7} key={index}>
                      <Box
                        sx={{
                          height: "40px",
                          width: "40px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: day?.available ? "pointer" : "default",
                          borderRadius: "50%",
                          m: 0.5,
                          bgcolor:
                            selectedDate &&
                            day?.day === selectedDate.getDate()
                              ? "primary.main"
                              : "transparent",
                          color:
                            selectedDate &&
                            day?.day === selectedDate.getDate()
                              ? "white"
                              : day?.available
                              ? "text.primary"
                              : "text.disabled",
                          opacity: day?.day ? 1 : 0,
                          "&:hover": {
                            bgcolor: day?.available
                              ? selectedDate &&
                                day?.day === selectedDate.getDate()
                                ? "primary.main"
                                : "action.hover"
                              : "transparent",
                          },
                        }}
                        onClick={() => handleDateSelect(day)}
                      >
                        {day?.day}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              {/* Legend */}
              <Stack direction="row" spacing={3} mt={2} justifyContent="center">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      bgcolor: "text.disabled",
                    }}
                  />
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Unavailable (Weekends)
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      bgcolor: "primary.main",
                    }}
                  />
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Selected
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>

          {/* Time Slots */}
          <Grid item xs={12} md={5}>
            <Paper elevation={0} sx={{ p: 3, borderRadius: 2 }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                Select a Time
              </Typography>

              {selectedDate ? (
                <>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "primary.main", mb: 2 }}
                  >
                    <CalendarMonth
                      fontSize="small"
                      sx={{ verticalAlign: "middle", mr: 0.5 }}
                    />
                    {formatSelectedDate(selectedDate)}
                  </Typography>

                  <Grid container spacing={1}>
                    {timeSlots.map((slot, index) => (
                      <Grid item xs={6} key={index}>
                        <Button
                          fullWidth
                          variant={selectedTimeSlot?.time === slot.time ? "contained" : "outlined"}
                          onClick={() => handleTimeSlotSelect(slot)}
                          sx={{ borderRadius: 2 }}
                        >
                          {slot.time}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <Box sx={{ textAlign: "center", py: 8 }}>
                  <Typography color="text.secondary">
                    Please select a date first to view available time slots.
                  </Typography>
                </Box>
              )}
            </Paper>

            {/* Booking Button */}
            {selectedDate && selectedTimeSlot && (
              <Paper elevation={0} sx={{ p: 3, borderRadius: 2, mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Ready to Book
                </Typography>
                <Typography variant="body2" paragraph sx={{ color: "text.secondary" }}>
                  You've selected <strong>{formatSelectedDate(selectedDate)}</strong> at <strong>{selectedTimeSlot.time}</strong>
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={() => setBookingFormOpen(true)}
                  sx={{ borderRadius: 2 }}
                >
                  Complete Booking
                </Button>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Container>

      {/* Booking Form Dialog */}
      <Dialog
        open={bookingFormOpen}
        onClose={() => setBookingFormOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Complete Your Booking</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Please provide your contact details to confirm your remote support session.
          </DialogContentText>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <PersonOutline sx={{ color: "text.secondary", mr: 1 }} />
                    ),
                  }}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <Email sx={{ color: "text.secondary", mr: 1 }} />
                    ),
                  }}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <Phone sx={{ color: "text.secondary", mr: 1 }} />
                    ),
                  }}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Additional Notes (Optional)"
                  name="notes"
                  value={formData.notes}
                  onChange={handleInputChange}
                  multiline
                  rows={2}
                  sx={{ mb: 2 }}
                />
              </Grid>
            </Grid>

            <DialogActions>
              <Button onClick={() => setBookingFormOpen(false)}>Cancel</Button>
              <Button type="submit" variant="contained">Confirm Booking</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Box sx={{ bgcolor: "#f0f0f0", py: 3, mt: "auto" }}>
        <Container maxWidth="lg">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="body2" color="text.secondary">
                © {new Date().getFullYear()} IT and Tech Consulting. All rights reserved.
              </Typography>
            </Grid>
            <Grid item>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 3 }}>
                <a href="/privacy-policy" underline="hover" color="text.secondary" variant="body2" style={{textDecoration: "none", color: "#333"}}>
                  Privacy Policy
                </a>
                <a href="/refund-policy" underline="hover" color="text.secondary" variant="body2" style={{textDecoration: "none", color: "#333"}}>
                  Refund Policy
                </a>
                <a href="/termsandconditions" underline="hover" color="text.secondary" variant="body2" style={{textDecoration: "none", color: "#333"}}>
                  Terms & Conditions
                </a>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Box sx={{ textAlign: "center", py: 3 }}>
            <CheckCircle color="success" sx={{ fontSize: 60, mb: 2 }} />
            <Typography variant="h5" gutterBottom>
              Booking Confirmed!
            </Typography>
            <Typography variant="body1" paragraph>
              Thank you for booking a remote support session with us. You will receive a confirmation email shortly with all the details.
            </Typography>
            <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
              <Typography variant="body2">
                <strong>Service:</strong> {service.name}
              </Typography>
              <Typography variant="body2">
                <strong>Date & Time:</strong> {selectedDate && formatSelectedDate(selectedDate)} at {selectedTimeSlot?.time}
              </Typography>
            </Paper>
            <Button
              variant="contained"
              onClick={() => {
                setConfirmationOpen(false);
                setSelectedDate(null);
                setSelectedTimeSlot(null);
                setFormData({
                  name: "",
                  email: "",
                  phone: "",
                  notes: "",
                });
              }}
            >
              Done
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default BookingPage;