import { Box, Container, Typography, Grid } from "@mui/material";

// Privacy Policy Page Component
const PrivacyPolicyPage = () => {
  return (
    <Box sx={{ py: { xs: "60px", sm: "80px" }, bgcolor: "#f5f5f0", width: "100%" }}>
      <Container maxWidth="lg">
        {/* Section Title */}
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "'Anonymous_Pro-Bold', Helvetica",
              fontWeight: "bold",
              color: "#1f1f20",
              fontSize: { xs: "2rem", sm: "2.8rem", md: "3.8rem" },
              lineHeight: "1.2",
              mb: 2,
            }}
          >
            Privacy Policy
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Figtree-Medium', Helvetica",
              fontWeight: 500,
              color: "#5d5c58",
              fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" },
              lineHeight: "1.7",
              maxWidth: "800px",
              mx: "auto",
            }}
          >
            This Privacy Policy outlines how we collect, use, and protect your personal information. By using our
            remote IT support services, you consent to the practices described in this policy.
          </Typography>
        </Box>

        {/* Policy Sections */}
        <Grid container spacing={4} sx={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={10}>
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                1. Information We Collect
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                We collect personal data such as your name, email address, phone number, billing information, and
                payment details when you sign up for our services. Additionally, we collect technical data such as
                your device type, IP address, and website usage to improve our service delivery.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                2. How We Use Your Information
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                We use the collected information to provide remote IT support, process payments, communicate with you
                regarding service updates, and improve our services. We may also use the information for legal
                compliance purposes.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                3. Data Sharing and Disclosure
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                We do not sell or rent your personal information. We may share your data with trusted third-party
                service providers, such as payment processors and cloud service providers, to help us deliver our
                services. We may also disclose your data to comply with legal obligations.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                4. Data Security
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                We implement security measures to protect your data from unauthorized access, alteration, or
                disclosure. However, no method of electronic storage or transmission is completely secure, and we
                cannot guarantee the absolute security of your data.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                5. Your Rights and Choices
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                You have the right to access, correct, delete, and restrict the use of your personal information. You
                may also object to the processing of your data or request a copy of your personal data in a portable
                format. To exercise these rights, please contact us at [Your Email Address].
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                6. Cookies and Tracking Technologies
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                We use cookies to improve your experience on our site and to track usage patterns. You can control
                cookie settings in your browser, but disabling cookies may affect some website features.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                7. Changes to This Privacy Policy
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                We may update this Privacy Policy to reflect changes in our practices or to comply with legal
                requirements. The updated policy will be posted on this page with a revised "Last Updated" date.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  fontWeight: 600,
                  color: "#1f1f20",
                  fontSize: "1.5rem",
                  mb: 2,
                }}
              >
                8. Contact Us
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                If you have any questions or concerns about this Privacy Policy, please contact us at:
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "'Figtree-Regular', Helvetica",
                  color: "#62615e",
                  fontSize: "1rem",
                  lineHeight: "1.7",
                  mb: 2,
                }}
              >
                Email: info@itandtechconsulting.com <br />
                Phone: +421 950 684 070 <br />
                Address: Hlavná 643/26 Kráľovský Chlmec 077 01 Slovakia 
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PrivacyPolicyPage;