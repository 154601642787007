import React from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './pages/Main';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import BookingPage from './pages/BookPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import RefundPolicyPage from './pages/RefundPolicy';
import LegalNoticePage from './pages/LegalNotice';
import TermsAndConditionsPage from './pages/TermsAndConditions';

function App() {
  return <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/book" element={<BookingPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/refund-policy" element={<RefundPolicyPage />} />
          <Route path="/terms-and-condition" element={<TermsAndConditionsPage />} />
          <Route path="/legal-notice" element={<LegalNoticePage />} />
        </Routes>
      </Router>
}

export default App;
