import { Box, Stack, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import BusinessIcon from "@mui/icons-material/Business";

export default function CompanyInfo() {
  return (
    <Box sx={{ }}>
      <Stack spacing={3}>

        {/* Address */}
        <Stack direction="row" spacing={2} alignItems="center">
          <LocationOnIcon sx={{ color: "#f88a3e", fontSize: 28 }} />
          <Typography
            variant="body2"
            sx={{
              fontFamily: "'Figtree-Regular', Helvetica",
              color: "#7d7775",
              fontSize: "0.95rem",
              lineHeight: 1.6,
            }}
          >
            Hlavná 643/26<br/>
            Kráľovský Chlmec 077 01<br/>
            Slovakia
          </Typography>
        </Stack>

        {/* Phone Number */}
        <Stack direction="row" spacing={2} alignItems="center">
          <PhoneIcon sx={{ color: "#f88a3e", fontSize: 28 }} />
          <Typography
            variant="body2"
            sx={{
              fontFamily: "'Figtree-Regular', Helvetica",
              color: "#7d7775",
              fontSize: "0.95rem",
              lineHeight: 1.6,
            }}
          >
            +421 950 684 070
          </Typography>
        </Stack>

        {/* Legal Name */}
        <Stack direction="row" spacing={2} alignItems="center">
          <BusinessIcon sx={{ color: "#f88a3e", fontSize: 28 }} />
          <Typography
            variant="body2"
            sx={{
              fontFamily: "'Figtree-Regular', Helvetica",
              color: "#7d7775",
              fontSize: "0.95rem",
              lineHeight: 1.6,
            }}
          >
            IT SUPPORT 1 s.r.o.
          </Typography>
        </Stack>

      </Stack>
    </Box>
  );
}