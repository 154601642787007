import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

// Since we can't use local imports, we'll keep the image imports as they are
// In a real implementation, these would need to be properly sourced
import bottomImg from "../assets/img/bottom-img.png";
import image1 from "../assets/img/image-1.png";
import image2 from "../assets/img/image-2.png";
import image3 from "../assets/img/image-3.png";
import image4 from "../assets/img/image-4.png";
import image5 from "../assets/img/image-5.png";
import image6 from "../assets/img/image-6.png";
import image7 from "../assets/img/image-7.png";
import image8 from "../assets/img/image-8.png";
import image9 from "../assets/img/image-9.png";
import image10 from "../assets/img/image-10.png";
import image11 from "../assets/img/image-11.png";
import image12 from "../assets/img/image-12.png";
import logoFooter from "../assets/img/logo-footer.png";
import logoNav from "../assets/img/logo-nav.png";
import CompanyInfo from "./CompanyInfo";
import RemoteSupportPackages from "./RemoteSupportPackages";

const Main = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  // Navigation links data
  const navLinks = [
    { title: "Home", path: "#" },
    { title: "About", path: "#" },
    { title: "Services", path: "#" },
    { title: "Contact", path: "#" },
  ];

  // Footer links data
  const footerLinks = {
    // quickLinks: ["Home", "About", "Services", "Contact"],
    legalInfo: [["Privacy policy","/privacy-policy"], ["Legal info","/legal-notice"], ["Refund policy","/refund-policy"], ["Terms and condition","/terms-and-condition"]],
  };

  // Section 6 data
  const reliableSolutionsData = [
    { title: "Support", description: "Antivirus Updates" },
    { title: "Troubleshooting", description: "Software Fixes" },
    {
      title: "System Optimization",
      description: "Comprehensive Remote Assistance",
    },
  ];

  return (
    <Box sx={{ bgcolor: "white", width: "100%" }}>
      {/* Navigation Bar */}
      <AppBar 
        position="fixed" 
        elevation={0} 
        sx={{ 
          bgcolor: "white", 
          height: { xs: "60px", sm: "70px" } 
        }}
      >
        <Container maxWidth="xl">
          <Toolbar sx={{ justifyContent: "space-between", px: { xs: 2, md: 5 } }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box
                component="img"
                src={logoNav}
                alt="Logo nav"
                sx={{ width: "48px", height: "48px", objectFit: "cover" }}
              />
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "'Inter-Regular', Helvetica",
                  color: "black",
                  fontSize: { xs: "1rem", sm: "1.25rem" },
                }}
              >
                IT&Tech consulting
              </Typography>
            </Stack>

            {!isMobile && (
              <Stack direction="row" alignItems="center" spacing={2}>
                {/* {navLinks.map((link, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    sx={{
                      fontFamily: "'Figtree-Regular', Helvetica",
                      color: "#9e9896",
                      cursor: "pointer",
                    }}
                  >
                    {link.title}
                  </Typography>
                ))} */}
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#020202",
                    borderRadius: "10px",
                    height: "40px",
                    fontFamily: "'Figtree-SemiBold', Helvetica",
                    color: "#d8d5ce",
                    textTransform: "none",
                  }}
                  onClick={()=>{
                    window.location.href = "/book"
                  }}
                >
                  Book Now
                </Button>
              </Stack>
            )}

            {isMobile && (
              <Button
                variant="contained"
                size="small"
                sx={{
                  bgcolor: "#020202",
                  borderRadius: "10px",
                  fontFamily: "'Figtree-SemiBold', Helvetica",
                  color: "#d8d5ce",
                  textTransform: "none",
                }}
                onClick={()=>{
                  window.location.href = "/book"
                }}
              >
                Book Now
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      {/* Hero Section */}
      <Box 
        sx={{ 
          pt: { xs: "80px", sm: "100px" },
          pb: { xs: "60px", sm: "80px" },
          bgcolor: "#fdf3eb", 
          width: "100%",
          overflow: "hidden",
          position: "relative",
          mt: { xs: "60px", sm: "70px" },
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Container maxWidth="xl" sx={{maxWidth: {
          xs: "90vw",  // mobile
          sm: "80vw",  // small screens
          md: "70vw",  // medium screens
          lg: "69vw",  // large screens
        }}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ position: "relative", mb: 2 }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontFamily: "'Anonymous_Pro-Bold', Helvetica",
                    fontWeight: "bold",
                    color: "#060505",
                    fontSize: { xs: "2.5rem", sm: "4rem", md: "5.5rem" },
                    textAlign: "center",
                    lineHeight: { xs: "1.1", sm: "0.9" },
                    mb: 3,
                  }}
                >
                  Reliable Remote Support for You
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "'Figtree-Medium', Helvetica",
                    color: "#9d9791",
                    fontSize: { xs: "1rem", sm: "1.2rem" },
                    textAlign: "center",
                    lineHeight: "1.6",
                    mx: "auto",
                    width: { xs: "100%", sm: "80%", md: "70%" },
                    mb: 4,
                  }}
                >
                  Expertly Solve Technical Issues, Update Antivirus, and Fix Errors
                  Remotely - No Waiting, No Queues, 24/7 Worldwide
                </Typography>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#f68a3f",
                      borderRadius: "40px",
                      fontFamily: "'Figtree-SemiBold', Helvetica",
                      color: "#72361e",
                      textTransform: "none",
                      fontSize: { xs: "1rem", sm: "1.2rem" },
                      py: { xs: 1.5, sm: 2 },
                      px: { xs: 4, sm: 6 },
                    }}
                    onClick={()=>{
                      window.location.href = "/book"
                    }}
                  >
                    Get Started
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>

        {/* Decorative elements positioned absolutely but with responsive sizing */}
        <Box
          sx={{
            position: "absolute",
            width: { xs: "20%", sm: "15%", md: "10%" },
            bottom: "10%",
            right: "5%",
            display: { xs: "none", md: "block" },
          }}
        >
          <Box
            component="img"
            src={image5}
            alt="Decorative element"
            sx={{
              width: "100%",
              height: "auto",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            width: { xs: "15%", sm: "10%", md: "8%" },
            top: "10%",
            left: "5%",
            display: { xs: "none", md: "block" },
          }}
        >
          <Box
            component="img"
            src={image1}
            alt="Decorative element"
            sx={{
              width: "100%",
              height: "auto",
            }}
          />
        </Box>
      </Box>

      {/* Section 1 */}
      <Box
        sx={{
          py: { xs: "60px", sm: "80px" },
          bgcolor: "#e7e6dd",
          width: "100%",
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={4} alignItems="center" sx={{flexWrap: {
            xs: "wrap",   // small screens: wrap (default, or you can change)
            sm: "nowrap",  // small screens
          }}}>
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} sx={{
              flex: { xs: "unset", sm: 1 } // ⬅️ flex 1 starting from sm and up, unset on mobile
            }}>
              <Box sx={{ px: { xs: 2, md: 4 } }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "'Figtree-Regular', Helvetica",
                    color: "#eea371",
                    fontSize: { xs: "0.9rem", sm: "1rem" },
                    mb: 1,
                  }}
                >
                  Fast and Secure Support
                </Typography>

                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: "'Anonymous_Pro-Bold', Helvetica",
                    fontWeight: "bold",
                    color: "#050404",
                    fontSize: { xs: "1.8rem", sm: "2.3rem", md: "2.6rem" },
                    lineHeight: "1.2",
                    mb: 2,
                  }}
                >
                  Streamlined Remote Assistance for All Your Needs
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "'Figtree-Medium', Helvetica",
                    fontWeight: 500,
                    color: "#605f5c",
                    fontSize: { xs: "1rem", sm: "1.1rem" },
                    lineHeight: "1.7",
                    mb: 3,
                  }}
                >
                  We Provide Comprehensive Remote IT Services to Keep Your Systems
                  Running Smoothly. Our Team of Experienced Technicians is Available
                  24/7 to Quickly Resolve Any Technical Problems You May Encounter
                </Typography>

                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#f88a3e",
                    borderRadius: "40px",
                    py: 1.5,
                    px: 3,
                    textTransform: "none",
                    fontFamily: "'Figtree-SemiBold', Helvetica",
                    fontWeight: 600,
                    color: "#712e12",
                    fontSize: { xs: "0.9rem", sm: "1.1rem" },
                  }}
                  onClick={()=>{
                    window.location.href = "/book"
                  }}
                >
                  Contact Us
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} sx={{
              flex: { xs: "unset", sm: 1 } // ⬅️ flex 1 starting from sm and up, unset on mobile
            }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  src={image6}
                  alt="Remote support illustration"
                  sx={{
                    width: "100%",
                    maxWidth: "550px",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Section 2 */}
      <Box
        sx={{
          py: { xs: "60px", sm: "80px" },
          bgcolor: "#fdf2ea",
          width: "100%",
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={4} alignItems="center" sx={{flexWrap: { xs: "wrap", sm: "nowrap"}}}>
            <Grid item xs={12} md={6} sx={{
              flex: { xs: "unset", sm: 1 } // ⬅️ flex 1 starting from sm and up, unset on mobile
            }}>
              <Box sx={{ width: "100%" }}>
                <Box
                  component="img"
                  src={bottomImg}
                  alt="Remote support illustration"
                  sx={{
                    width: "100%",
                    maxWidth: "550px",
                    height: "auto",
                    objectFit: "contain",
                    display: "block",
                    mx: { xs: "auto", md: 0 },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6} sx={{
              flex: { xs: "unset", sm: 1 } 
            }}>
              <Box sx={{ px: { xs: 2, md: 4 } }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "'Figtree-Medium', Helvetica",
                    fontWeight: 500,
                    color: "#f4ae84",
                    fontSize: { xs: "0.9rem", sm: "1.1rem" },
                    mb: 1,
                  }}
                >
                  Expert Solutions
                </Typography>

                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: "'Anonymous_Pro-Bold', Helvetica",
                    fontWeight: "bold",
                    color: "#1f2729",
                    fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
                    lineHeight: "1.2",
                    mb: 2,
                  }}
                >
                  Efficient Remote Troubleshooting
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "'Figtree-Medium', Helvetica",
                    fontWeight: 500,
                    color: "#706b69",
                    fontSize: { xs: "1rem", sm: "1.1rem" },
                    lineHeight: "1.7",
                    mb: 3,
                  }}
                >
                  Enjoy Hassle-Free Technical Support with Our Reliable and
                  Responsive Remote Helpdesk Service. Our Skilled Technicians Are
                  Dedicated to Solving Your Issues Quickly and Effectively,
                  Ensuring Your Systems Stay Optimized and Secure
                </Typography>

                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#fd7d3b",
                    borderRadius: "40px",
                    py: 1.5,
                    px: 3,
                    textTransform: "none",
                    fontFamily: "'Figtree-SemiBold', Helvetica",
                    fontWeight: 600,
                    color: "#fedcc9",
                    fontSize: { xs: "0.9rem", sm: "1.1rem" },
                  }}
                  onClick={()=>{
                    window.location.href = "/book"
                  }}
                >
                  Book Now
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Section 3 */}
      <Box
        sx={{
          py: { xs: "60px", sm: "80px" },
          bgcolor: "#e5e7da",
          width: "100%",
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={4} alignItems="center" sx={{flexWrap: { xs: "wrap", sm: "nowrap"}}}>
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}  sx={{
              flex: { xs: "unset", sm: 1 } 
            }}>
              <Box sx={{ px: { xs: 2, md: 4 } }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "'Figtree-Regular', Helvetica",
                    color: "#f18f55",
                    fontSize: { xs: "0.9rem", sm: "1rem" },
                    mb: 1,
                  }}
                >
                  Quality Service
                </Typography>

                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: "'Anonymous_Pro-Bold', Helvetica",
                    fontWeight: "bold",
                    color: "#151c20",
                    fontSize: { xs: "1.8rem", sm: "2.3rem", md: "2.6rem" },
                    lineHeight: "1.2",
                    mb: 2,
                  }}
                >
                  Trusted Remote IT Assistance for Individuals and Businesses
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "'Figtree-Medium', Helvetica",
                    fontWeight: 500,
                    color: "#5b5c57",
                    fontSize: { xs: "1rem", sm: "1.1rem" },
                    lineHeight: "1.7",
                    mb: 3,
                  }}
                >
                  Our Remote Helpdesk Experts Are Here to Provide Prompt and
                  Effective Solutions, Whether It's Updating Your Antivirus, Fixing
                  Software Errors, or Optimizing Your System Performance
                </Typography>

                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#fb7c3d",
                    borderRadius: "40px",
                    py: 1.5,
                    px: 3,
                    textTransform: "none",
                    fontFamily: "'Figtree-SemiBold', Helvetica",
                    fontWeight: 600,
                    color: "#ffdbc2",
                    fontSize: { xs: "0.9rem", sm: "1.1rem" },
                  }}
                  onClick={()=>{
                    window.location.href = "/book"
                  }}
                >
                  Learn More
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}  sx={{
              flex: { xs: "unset", sm: 1 } 
            }}>
              <Box sx={{ width: "100%" }}>
                <Box
                  component="img"
                  src={image10}
                  alt="Remote IT support illustration"
                  sx={{
                    width: "100%",
                    maxWidth: "550px",
                    height: "auto",
                    objectFit: "contain",
                    display: "block",
                    mx: { xs: "auto", md: 0 },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Section 4 */}
      <Box
        sx={{
          py: { xs: "60px", sm: "80px" },
          bgcolor: "#fdf3ea",
          width: "100%",
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={4} alignItems="center" sx={{flexWrap: { xs: "wrap", sm: "nowrap"}}}>
            <Grid item xs={12} md={6} sx={{
              flex: { xs: "unset", sm: 1 } 
            }}>
              <Box sx={{ width: "100%" }}>
                <Box
                  component="img"
                  src={image11}
                  alt="Remote IT support illustration"
                  sx={{
                    width: "100%",
                    maxWidth: "550px",
                    height: "auto",
                    objectFit: "contain",
                    display: "block",
                    mx: { xs: "auto", md: 0 },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6} sx={{
              flex: { xs: "unset", sm: 1 } 
            }}>
              <Box sx={{ px: { xs: 2, md: 4 } }}>
                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: "'Anonymous_Pro-Bold', Helvetica",
                    fontWeight: "bold",
                    color: "#2a2b2a",
                    fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
                    lineHeight: "1.2",
                    mb: 2,
                  }}
                >
                  Comprehensive Remote IT Support
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "'Figtree-Medium', Helvetica",
                    fontWeight: 500,
                    color: "#97938d",
                    fontSize: { xs: "1rem", sm: "1.1rem" },
                    lineHeight: "1.7",
                    mb: 3,
                  }}
                >
                  Experience Seamless Remote Assistance with Our Dedicated Team of
                  IT Professionals. We Offer 24/7 Support to Quickly Resolve Any
                  Technical Issues You May Face
                </Typography>

                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#e77933",
                    borderRadius: "40px",
                    py: 1.5,
                    px: 3,
                    textTransform: "none",
                    fontFamily: "'Figtree-SemiBold', Helvetica",
                    fontWeight: 600,
                    color: "#fcd5b5",
                    fontSize: { xs: "0.9rem", sm: "1.1rem" },
                  }}
                  onClick={()=>{
                    window.location.href = "/book"
                  }}
                >
                  Get Started
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Section 5 - Reliable Remote Solutions */}
      <RemoteSupportPackages/>

      {/* Footer */}
      <Box
        component="footer"
        sx={{
          py: { xs: 4, sm: 6, md: 8 },
          px: { xs: 2, sm: 4, md: 6 },
          bgcolor: "white",
          width: "100%",
          boxSizing: "border-box"
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Stack spacing={2.5}>
                <Box
                  component="img"
                  src={logoFooter}
                  alt="Logo footer"
                  sx={{ width: "96px", height: "96px", objectFit: "cover" }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "'Figtree-Regular', Helvetica",
                    color: "#7d7775",
                    fontSize: "0.9rem",
                    lineHeight: "1.5",
                  }}
                >
                  © 2025 ITandTechConsulting
                  <br />
                  All rights reserved.
                </Typography>
              </Stack>
            </Grid>
            
            <Grid sx={{flex: 1, minWidth: "50px"}}></Grid>

            {Object.entries(footerLinks).map(([category, links], index) => (
              <Grid item xs={6} sm={4} md={3} key={index} sx={{width: {xs: "100%",sm: "unset"}}}>
                <Stack spacing={1.5}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontFamily: "'Figtree-SemiBold', Helvetica",
                      fontWeight: 600,
                      color: "#7d7775",
                      fontSize: "0.9rem",
                      mb: 1,
                      textTransform: "capitalize"
                    }}
                  >
                    {category.replace(/([A-Z])/g, ' $1').trim()}
                  </Typography>

                  {links.map((link, linkIndex) => (
                    <a href={link[1]} style={{textDecoration:"none", color: "#333"}}>
                      <Typography
                        key={linkIndex}
                        variant="body2"
                        sx={{
                          fontFamily: "'Figtree-Regular', Helvetica",
                          color: "#7d7775",
                          fontSize: "0.9rem",
                          cursor: "pointer",
                        }}
                      >
                        {link[0]}
                      </Typography>
                    </a>
                  ))}
                </Stack>
              </Grid>
            ))}
            <CompanyInfo/>
            <Grid sx={{width: {
              xs: "unset",
              sm: "50px"
            }}}></Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Main;